@import "util";

@mixin bubble-base() {
  // https://leaverou.github.io/bubbly/
  .bubble {
    @extend .flex-center;
    position: relative;
    //background: #00aabb;
    border-radius: .4em;
    padding: 1rem;
    margin-bottom: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    min-height: 8rem;
    width: 80%;
  
    &.bubble:after {
      content: '';
      position: absolute;
      border: 42px solid transparent;
    }
    &.bubble-right:after {
      right: 0;
      top: 50%;
      width: 0;
      height: 0;
      border-right: 0;
      border-top: 0;
      margin-top: -21px;
      margin-right: -42px;
    }
    &.bubble-left:after {
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      border-left: 0;
      border-top: 0;
      margin-top: -21px;
      margin-left: -42px;
    }
    &.bubble-bottom:after {
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border-bottom: 0;
      border-right: 0;
      margin-left: -21px;
      margin-bottom: -42px;
    }
    &.bubble-top:after {
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border-top: 0;
      border-right: 0;
      margin-left: -21px;
      margin-top: -42px;
    }
  }
}
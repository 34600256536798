@import "util";

@mixin color-base() {
  a {
    color: clr(tertiary);
  }
  .fg1 { color: clr(primary); }
  .fg1-l { color: clr(primary-light); }
  .fg1-d { color: clr(primary-dark); }
  .fg1-dr { color: clr(primary-darker); }
  .fg1-dst { color: clr(primary-darkest); }
  .bg1 { 
    background-color: clr(primary);

    h1 {
      color: clr(primary-darkest);
    }
    span {
      color: clr(tertiary);
    }
    .bubble {
      background-color: clr(secondary);
      &.bubble-right:after  {
        border-left-color: clr(secondary);
      }
      &.bubble-left:after  {
        border-right-color: clr(secondary);
      }
      &.bubble-bottom:after  {
        border-top-color: clr(secondary);
      }
    }
  }
  .bg1-l { 
    background-color: clr(primary-light);
    h1 {
      color: clr(primary-darkest);
    }
    span {
      color: clr(secondary);
    }
  }
  .bg1-d { 
    background-color: clr(primary-dark); 
  }
  .bg1-dr { background-color: clr(primary-darker); }
  .bg1-dst { background-color: clr(primary-darkest); }

  .fg2 { color: clr(secondary); }
  .fg2-l { color: clr(secondary-light); }
  .fg2-d { color: clr(secondary-dark); }
  .bg2 { background-color: clr(secondary); }
  .bg2-l { 
    background-color: clr(secondary-light); 
    h1 {
      color: clr(primary-darker);
    }
    span {
      color: clr(primary-darker);
    }
  }
  .bg2-d { background-color: clr(secondary-dark); }

}
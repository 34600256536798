
@mixin reset-base() {
  @at-root {
    body {
      font-size: 100%;
      margin: 0;
      padding: 0;
    }

    *, *:before, *:after {
      box-sizing: border-box;

    }

    div, header, footer {
      position: relative;
    }

    *:focus {
      outline: none;
    }

    iframe {
      border: none;
    }
  }
}
// common
$primary: #f9f9ff;
$primary-light: #fff;
$primary-dark: #c6c6cc;
$primary-darker: #666;
$primary-darkest: #222;

$secondary: #fab700;
$secondary-light: #ffe94c;
$secondary-dark: #c28800;

$tertiary: #DC334F;

/* bootstrap */
// custom
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary
);


// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/buttons";

/* light theme */
@import "../components/index";


$font-primary: #2C3160;

.light {

  $theme: (
    colors: (
      primary: $primary,
      primary-light: $primary-light,
      primary-dark: $primary-dark,
      primary-darker: $primary-darker,
      primary-darkest: $primary-darkest,

      secondary: $secondary,
      secondary-light: $secondary-light,
      secondary-dark: $secondary-dark,

      tertiary: $tertiary,

      font-dark: #333,
      font-light: #fff,
    ),
    config: (
      contrastPoint : 64,
    ),
    application: (
      font-family: #{'Roboto', sans-serif},
    )
  );
  @include index($theme);


}
@import "util";

$heroImg: url("../../assets/vent-washed.jpg");
$service-area: url("../../assets/PLJ-service-map.png");

@mixin layout-base() {

  // heights
  $header: 100px;
  $footer: 60px;
  $hero: 70vh;
  $content-min: 70vh;
  
  #page {
    min-height: 100vh;
    max-width: 100vw;
    background: clr(primary);
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-areas: "hero" "content" "footer";
  }

  #header {
    grid-area: header;
    color: chooseContrastColor(primary-light);
    background: clr(primary-light);
    box-shadow: 0px 25px 30px 0px rgba(158, 158, 158, 0.3);
    z-index: 100;
    padding: 20px 0;
    position: relative;
    width: 100vw;
    margin: 0;
    margin-top: 30px;

    > .col {
      background: clr(primary-light);
    }

    .col-pnlj, .col-carrier, .col-auth-logo {
      @extend .flex-center;
    }
    .col:first-child, .col:last-child {
      display: none;
    }
    .logo {
      height: 100%;
    }

    .col-carrier {
      padding-bottom: 10px;
      a {
        img {
          height: 60px;
        }
      }
    }
    .col-auth-logo {
      padding-top: 1rem;
      a {
        height: 100%;
        img {
          height: 90px;
        }
      }
    }
  }

  li.nav-item a {
    padding-right: 2rem;
    font-weight: bold;
  }

  #hero {
    grid-area: hero;
    color: #333;
    background: $heroImg 50% 40%;
    background-size: cover;
    min-height: 70vh;

    .shadow {
      background: rgba(0, 0, 0, .5);
    }
    .jumbotron {
      color: clr(font-light);
      background-color: transparent;
      padding: 40px 5px 30px 5px;
      hr {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
      }
      .call-us {
        a {
          color: clr(secondary);
        }
      }
    }
  }
  #mini-hero {
    background: $heroImg 50% 40%;
    background-size: cover;
    padding-bottom: 50px;
    margin-bottom: 1rem;
    .shadow {
      background: rgba(0, 0, 0, .5);
    }

  }

  .content {
    min-height: 50vh;
  }

  #content {
    grid-area: content;
    min-height: 100vh;

    > .row {
      border-bottom: 1px solid #fab700;
      border-bottom: 1px solid #fab70099;
      padding: 30px 0;
    }

    #contentContainer {
      .col {
        padding: 30px 20px;
      }
    }

    h1 {
      padding-bottom: 10px;
    }

    span {
      font-weight: 500;
      text-transform: uppercase;
    }

    .city-scroll-list li {
      display: inline-block;
      padding-right: 6px;

      &:after {
        content: ",";
      }

      &:last-child:after {
        content: "";
      }
    }

    .service-area {
      background: $service-area;
      background-size: cover;
      background-position: center;
      height: 100%;
      width: 100%;
      min-height: 47vh;
      box-shadow: 10px 10px #8888;
    }
  }

  #conditions {
    @extend .flex-center;
    color: chooseContrastColor(primary-darker);
    background: clr(primary-darker);
    border-bottom: none !important;
  }

  #footer {
    @extend .flex-center;
    grid-area: footer;
    color: chooseContrastColor(primary-darker);
    background: clr(primary-darker);
    padding: 20px 20px 40px 20px;

    &.row {
      .col {
        flex-basis: auto;
      }
    }

    p, h3 {
      text-align: center;
    }
  }

  .reasons {
    display: flex;
    .col {
      flex: 1;
    }
    .reasonCard {
      @extend .flex-center;
      justify-content: start;
      flex-direction: column;
      background: white;
      width: 100%;
      min-height: 7rem;
      height: 95%;
      padding: .5rem 1.25rem;
      margin-bottom: .5rem;

      h3 {
        text-align: center;
      }

      p {
        text-align: center;
      }

      .svg-inline--fa {
        width: 3rem;
        height: 3rem;
        color: clr(tertiary);
        margin: .5rem;
      }
    }
  }

  .testimonials-list {
    list-style-type: none;
    padding: 0;
    overflow-x: hidden;

    li {
      overflow-x: auto;
    }

    h3 {
      border-bottom: 2px solid clr(tertiary);
      width: 95%;
    }
    
    img {
      max-width: 90vw;
      margin: 1rem auto;
    }
  }

  .loading-spinner {
    max-width: 10vw;
    position: absolute;
    left: 45%;
    top: 40%;
  }

  .page-testimonials {
    h2 {
      color: clr(secondary)
    }
  }
}

@mixin layout-small() {

  #header {
    padding: 30px 0;
    margin-top: 50px;
    .col-pnlj {
      //padding-left: 30px;
    }
    .col-carrier {
      a {
        img {
          height: 90px;
        }
      }
    }
    .col-auth-logo {
      a {
        img {
          height: 120px;
        }
      }
    }
  }

  #content {
    .service-area {
      min-height: auto;
      max-height: 400px;
      max-width: 400px;
    }
  }

  #footer {
    &.row {
      .col {
        flex-basis: 0;
      }
    }
  }

}
@mixin layout-medium() {
  
  #header {
    .col-pnlj {
      padding-left: 0;
    }

    .col:first-child, .col:last-child {
      display: flex;
    }
  }
}
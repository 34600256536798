@import "util";

@mixin products-base() {

  .product-nav {
    background-color: clr(secondary-light) !important;
    border-bottom: 3px solid rgba(250,183,0,.6);
    margin-bottom: 20px;
    border-right: 3px solid rgba(250,183,0,.6);  
  }

  .nav-item {
    line-height: 2rem;
    a {
      color: clr(tertiary) !important;
      text-decoration: underline;
      &:hover {
        color: lighten(clr(tertiary), 10%);
      }
    }
  }

  .product-list {
    margin-bottom: 30px;
    background-color: #eefa;
    a {
      color: clr(secondary);
    }
  }
  
  .product-line {
    background-color: silver;
  }
  .product-line {
    h3 {
      padding: 0.5rem;
    }
  }
  .product-item {
    display: inline-flex;
    flex-direction: column;
    width: 98%;
    min-height: 15rem;
    margin: 1rem 1% 2rem 1%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    box-shadow: 0px 10px 17px 0px rgba(170,170,170,1);

    .product-image {
      align-self: center;
      max-height: 15rem;
    }
    .model-number {
      font-weight: bold;
      color: clr(tertiary);
      margin: 0 1rem;
    }
    .product-body {
      color: black;
      margin: 0 1rem;
    }

    &:hover {
      cursor: pointer;
      background-color: #ddd5;
    }
    h4 {
      align-self: flex-start;
      background: #3339;
      color: white;
      padding: 0.5rem;
      width: 100%;
    }
  }

  .product-promotion {
    color: clr(primary);
    background-color: clr(tertiary);
    font-size: 1.75rem;
    margin: 1rem 1% 0.5rem 1%;
    padding: 1rem;
    box-shadow: 0px 10px 17px 0px #aaaaaa;
    a {
      color: clr(secondary-light)
    }
  }
}

@mixin products-medium() {
  .product-item {
    width: 48%;
  }

  .nav-item {
    font-size: 1.2rem;
    text-align: center;
  }
  
}
@mixin font-base() {
  body, *, *:before, *:after {
    font-family: app(font-family);
  }

  p {
    line-height: 1.6rem;
  }
  
  sub {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 20px;
    display: inline-block;
  }
}
$no-color: #39ff14; // worst green I could find

.flex-center {
  align-items: center;
  justify-content: center;
  display: flex;
}

// Returns a color by name from the theme
@function clr($name) {
  @if map-has-key(map-get($theme, colors), $name) {
    @return map-get(map-get($theme, colors), $name);
  }
  @else {
    @return $no-color; // no color found - make obvious an error has happened
  }
}

// Returns a config setting from the theme
@function config($name) {
  @if map-has-key(map-get($theme, config), $name) {
    @return map-get(map-get($theme, config), $name);
  }
  @else {
    @return "missing-config-" + $name; //no config found
  }
}

// Returns an application config setting from the theme
@function app($name) {
  @if map-has-key(map-get($theme, application), $name) {
    @return map-get(map-get($theme, application), $name);
  }
  @else {
    @return "missing-app-setting-" + $name; //no config found
  }
}

// Simple way to determine text color based on background contrast
// google: sass choose-contrast-color
@function chooseContrastColor($name) {
  $color: clr($name);
  @if (lightness($color) > config(contrastPoint)) {
    @return clr(font-dark);
  } @else {
    @return clr(font-light);
  }
}
// A map of our mobile-first breakpoints
$sizes: (
  base-up: 0px,
  small-up: 576px,
  medium-up: 768px,
  large-up: 992px,
  xlarge-up: 1200px
);

// Accepts a named breakpoint, and renders mixin's content
// inside a media query for that size
@mixin forSize($breakpoint) {
  @if $breakpoint == base-up {
    @content;
  }
  @else {
    @media (min-width: map-get($sizes, $breakpoint)) {
      @content;
    }
  }
}